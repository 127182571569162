
//设置cookie
function setCookie(name, value, iDay){
  var oDate = new Date();
  oDate.setDate(oDate.getDate()+iDay);
  console.log(name + '=' + value + ';expires=' + oDate)
  return document.cookie = name + '=' + value + ';expires=' + oDate;
};


//获取cookie
function getCookie(name){
    let cookies = document.cookie;
    let cookieArr = cookies.split(";");

    for(let i = 0; i < cookieArr.length; i++){
        let ite = cookieArr[i].split("=");
        if (ite[0].trim() === name)
        return ite[1];
    };
    return null;
};

//清除cookie  
function clearCookie(name) {
    setCookie(name, "", -1);  
};

export {
    getCookie,
    clearCookie
}