import { lazy } from 'react'

const routes = [
  {
    path: '/zhaocaicenter',
    component: lazy(() => import(/* webpackChunkName:"zhaocaicenter" */ '@p/zhaocaicenter')),
    meta: {
      title: '招采中台',
    },
    routes: [
      {
        path: '/zhaocaicenter/pay',
        component: lazy(() => import(/* webpackChunkName:"pay" */ '@p/pay')),
        exact: true,
        meta: {
          title: '采购付款',
        },
      },
      {
        path: '/zhaocaicenter/offer',
        component: lazy(() => import(/* webpackChunkName:"offer" */ '@p/offer')),
        exact: true,
        meta: {
          title: '我的报价',
        },
      },
      {
        path: '/zhaocaicenter/contract',
        component: lazy(() => import(/* webpackChunkName:"contract" */ '@p/contract')),
        exact: true,
        meta: {
          title: '我的合同',
        },
      },
      {
        path: '/zhaocaicenter/demand',
        component: lazy(() => import( /* webpackChunkName:"Demand" */ '@p/Demand')),
        exact: true,
        meta: {
          title: '采购需求',
        },
      },
      {
        path: '/zhaocaicenter/contract/upload/:id/:procurementId',
        component: lazy(() => import(/* webpackChunkName:"uploadcontract" */ '@p/uploadcontract')),
        meta: {
          title: '我的合同',
        },
      },
      {
        path: '/zhaocaicenter/Demand/supplier',
        component: lazy(() => import(/* webpackChunkName:"Supplier" */ '@p/Demand/Supplier')),
        meta: {
          title: '选择供应商',
        },
      },
    ],
  },
  {
    path: '/zhaocaifront',
    component: lazy(() => import(/* webpackChunkName:"zhaocaifront" */ '@p/zhaocaifront')),
    meta: {
      title: '付款',
    },
    routes: [
      {
        path: '/zhaocaifront/gopay/:id',
        component: lazy(() => import(/* webpackChunkName:"gopay" */ '@p/gopay')),
        exact: true,
        meta: {
          title: '采购付款',
        },
      },
    ],
  },
  {
    path: '/home',
    component: lazy(() => import(/* webpackChunkName:"index" */ '@p/index')),
    meta: {
      title: '招采首页',
    },
  },
  {
    path: '/buyerdemandsdetail',
    component: lazy(() => import(/* webpackChunkName:"buyerdemandsdetail" */ '@p/buyerdemandsdetail')),
    meta: {
      title: '采购需求详情',
    },
  },
  {
    path: '/submitquotation',
    component: lazy(() => import(/* webpackChunkName:"submitquotation" */ '@p/submitquotation')),
    meta: {
      title: '提交报价',
    },
  },
  {
    path: '/cs',
    component: lazy(() => import('@p/cs')),
    meta: {
      title: 'cs',
    },
  }
]

export default routes
