import React, { Component } from 'react'
// Redirect
import { withRouter } from 'react-router-dom'
import ViewRouter from './router'
import request from '@api/index'
import { connect } from 'react-redux'
import changeUserInfoAction from '@redux/actions/useInfo.js'

class App extends Component {
  state = {
    getInfoFlag: false,
  }
  componentDidMount() {
    this.getInfo()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      //监听路由变化了之后
      this.setState({
        getInfoFlag: false,
      },()=>{
        this.getInfo()
      })
    }
  }

  getInfo = () => {
    request('/b2b/login/info')
      .then((data) => {
        this.setState({
          getInfoFlag: true,
        })
        if (data.code === '0') {
          this.props.changeUserInfo(data.data)
        }
      })
      .catch((err) => console.log(err, 'err'))
  }
  render() {
    if (!this.state.getInfoFlag) {
      return <></>
    }
    return (
      <div>
        <ViewRouter />
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    changeUserInfo: (data) => dispatch(changeUserInfoAction(data)),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(App))
