import { Switch, Route } from 'react-router-dom'
import { Suspense } from 'react'
import {Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const RouterView = (props) => {
  let { route } = props
  const identityList = useSelector(state=>state.userInfo.identityList)
 
  return (
    <Suspense fallback={<div>loading</div>}>
      <Switch>
        {route.map((item, index) => {
          return item.path ? (
            <Route
              key={index}
              exact={item.exact}
              path={item.path}
              render={(props) => {
                document.title = item.meta.title || '企业招采'
                return <item.component route={item.routes} {...props} />
              }}
            ></Route>
          ) : (
            <Route key={index} component={item.component} />
          )
        })}
        {identityList && identityList.length === 2 ? (
          <Redirect from="/zhaocaicenter" to="/zhaocaicenter/demand"></Redirect>
        ) : (
          <Redirect from="/zhaocaicenter" to="/zhaocaicenter/offer"></Redirect>
        )}
        <Redirect from="/" to="/home"></Redirect>
      </Switch>
    </Suspense>
  )
}

export default RouterView
