import {CHANGEUSERINFO} from '../constant'

const initState = {
    companyId:'',
    identityList:[],
    staffCode:''
};

export default function allInfo(state=initState,action){
	const {type,data} = action
	switch (type) {
		case CHANGEUSERINFO: 
            let dataCopy = JSON.parse(JSON.stringify(state));
            dataCopy = data;
			return dataCopy
		default:
			return state
	}
}
