/**
 * axios
 */
import { getCookie } from "@u/js/useCookie"
import axios from "axios";
import { message } from "antd";


import baseURL from "./baseUrl"
import qs from 'qs'

axios.defaults.timeout = 50000;
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Content-Type'] = "application/x-www-form-urlencoded; charset=UTF-8";
axios.defaults.headers.post['X-Requested-With'] = "XMLHttpRequest";
axios.defaults.baseURL = baseURL



// const paths = [
//     "/zhaocai/supplierprice/changesupperlierpriceinfo",
//     "/zhaocai/supplierprice/getsupplierpricelist",
//     "/zhaocai/supplierprice/getprocurementsupplierpriceinfo"
// ]
/**
 * 请求 -- 拦截器
 */
axios.interceptors.request.use(
    config => {
        // paths.indexOf(config.url) !== -1 && (config["baseURL"] = "/opui");
        config["headers"]["ECP-COOKIE"] = getCookie("ecptst_id")
        // config.headers['ECP-COOKIE'] = 'd86fcd93e83447e39aadacdfd0e0690e'
        return config;
    },
    (error) => {
        return Promise.reject(error.message);
    }
);
/**
 * 响应 -- 拦截器
 */
axios.interceptors.response.use(
    response => {
        // console.log("响应 -- 拦截器:",response);
        return response;
    },
    (error) => {
        // console.log("请求出错：", error.message);
    }
);


export default function request(url, data = {}, type = 'post', contentType = 'application/json;charset=utf-8') {
    let dataP = contentType === "application/json;charset=utf-8" ? data:qs.stringify(data)
    
    return new Promise((resolve, reject) => {
        axios({
            method: type,
            url: url,
            data: dataP,
            headers: { 'Content-Type': contentType },
        }).then(res => {

            //退出接口
            if(url === '/b2b/login/logout'){
               window.location.href = window.location.origin+"/home"
            }

            //登录接口直接resolve
            if(url ==='/b2b/login/info'){
                resolve(res.data);
                if(res.data.code !== "0" && window.location.pathname === '/home'){
                    message.error('登录状态已超时，请登录！')
                }

                if(res.data.code ==="0" && !res.data.data.companyId && window.location.pathname === '/home'){
                    message.error('很抱歉！  您还没有认证成功请先认证！')
                }
                return;
            }

            //付款获取二维码的接口
            if(url ==='/b2b/duolapaycmn/sms'){
                resolve(res.data.values);
                return;
            }

            //付款支付接口
            if(url ==='/zhaocai/ordzc/goPay'){
                resolve(res.data);
                return;
            }

            //未登录处理
            if(res.data.errorMessage && res.data.errorMessage[0].code === 'webcore.000003'){
                window.location.href = window.location.origin+"/home";
            }
            //未认证
            if(url ==='/b2b/login/info' && res.data.code ==="0" && !res.data.data.companyId){
                window.location.href = window.location.origin+"/home";
            }
            //code -2 未登录
            if(res.data.code === "-2"){
                window.location.href = window.location.origin+"/home"
            }

            if(res.data.code === "0"){
                return resolve(res.data);
            }else{
                //登录态过期
                if(res.data.code === '-1' && res.data.msg === "失败:登录状态已变更，请刷新页面！"){
                    message.warn('登录账号与操作内容不匹配，系统已自动刷新。');
                    setTimeout(()=>{
                        window.location.reload();
                    },2000)
                    return;
                }


                //去掉服务器内部异常：或者参数错误：
                if(res.data.msg.indexOf(':') !== -1){
                    let msg = res.data.msg.split(':')
                    message.error(msg[1])
                }else{
                    message.error(res.data.msg || "服务异常，请稍后重试！")
                }
               
            }
        }).catch(error => {
            message.error('请求出错了: ' + error.message)
        })
    })
}
